
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const osservatorioPage = props => {
	return (
		<CaseStudyPage link="osservatorio" title="Branding, Comunicazione, Sviluppo - Osservatorio"
		relPath="osservatorio" description="Ragioneria dello stato e Eurispes. Raccolta, analisi e diffusione per la ripresa.">
		</CaseStudyPage>
	);
};

export default osservatorioPage;
